import React from "react"
import { designations } from "../assets/data/expertise.js"
import certificate from "../assets/icons/certificate.svg"
import { v4 as uuidv4 } from "uuid"

const AboutExpertiseDesignations = () => {
  return (
    <div className="expertise__list expertise__list--designation">
      <h2 className="expertise__subtitle">
        <img
          src={certificate}
          className="icon icon--designation"
          alt="certificate icon for the designations section"
        />
        Designations
      </h2>
      {designations.map(item => {
        return <p key={uuidv4()}>{item}</p>
      })}
    </div>
  )
}
export default AboutExpertiseDesignations
