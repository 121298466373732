import React from "react"
import "./aboutExpertise.scss"

import AboutExpertiseExperience from "../components/aboutExpertiseExperience.js"
import AboutExpertiseEducation from "../components/aboutExpertiseEducation.js"
import AboutExpertiseDesignations from "../components/aboutExpertiseDesignations.js"

const AboutExpertise = () => {
  return (
    <div className="expertise">
      <h1 className="expertise__title">My Expertise</h1>
      <div>
        <AboutExpertiseExperience />
        <span>
          <AboutExpertiseEducation />
          <AboutExpertiseDesignations />
        </span>
      </div>
    </div>
  )
}

export default AboutExpertise
