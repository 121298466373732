import React from "react"
import "./aboutConnectNav.scss"

const AboutConnectNav = () => {
  return (
    <aside className="sub-nav connect-nav">
      <ul>
        <li>
          <a href="mailto: richardfababeir@yahoo.ca" target="blank">
            <p>email</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/richard-fababeir-5025a923/"
            target="blank"
          >
            <p>linkedIn</p>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/richard.fababeir" target="blank">
            <p>facebook</p>
          </a>
        </li>
        <li>
          <a href="https://www.homelife.ca/Richard-Fababeir" target="blank">
            <p>home life</p>
          </a>
        </li>
      </ul>
    </aside>
  )
}

export default AboutConnectNav
