import React from "react"
import "./aboutCompanyLogos.scss"
import { workList, qualifications } from "../assets/data/expertise.js"
import { v4 as uuidv4 } from "uuid"

const AboutCompanyLogos = () => {
  return (
    <div className="companies">
      <div>
        {workList.map(item => {
          if (item.logo) {
            return (
              <img
                src={item.logo.image}
                className={`companies__logo${
                  item.company === "MGR Construction Services" ? "--mgr" : ""
                }`}
                alt=""
                key={uuidv4()}
              />
            )
          } else {
            return ""
          }
        })}
      </div>
      <div>
        {qualifications.map(
          item => {
            // if (item.logo) {
            return (
              <img
                src={item.logo.image}
                className="companies__school-logo"
                alt=""
                key={uuidv4()}
              />
            )
          }
          // }
        )}
      </div>
    </div>
  )
}

export default AboutCompanyLogos
