import heathwood from "../images/heathwood.png"
import century from "../images/century2.png"
import homelife from "../images/homelife.png"
import markham from "../images/markhamTransparent.png"
import mgr from "../images/mgr.jpeg"
import diorite from "../images/dioriteLogoTransparent3.png"
import nu from "../images/nationalUniversity.png"
import seneca from "../images/seneca.svg"
import uoft from "../images/UofT.png"
import orea from "../images/OREA.png"

export const workList = [
  {
    start: "May 1996",
    finish: "October 2000",
    company: "Heathwood Homes",
    title: "Site Superintendent",
    logo: {
      image: heathwood,
      relativePath: "assets/images/heathwood.png",
      imageSlug: "heathwood.png",
    },
  },
  {
    start: "November 2000",
    finish: "October 2010",
    company: "Brookstone Developments",
    title: "Site Superintendent",
    // logo:
    // { relativePath: "",
    // imageSlug: "",}
  },
  {
    start: "2010",
    finish: "2013",
    company: "Century 21 Innovative Reality Inc",
    title: "Realtor",
    logo: {
      image: century,
      relativePath: "assets/images/century21.png",
      imageSlug: "century21.png",
    },
  },
  {
    start: "October 2011",
    finish: "March 2014",
    company: "Corporation of the City of Markham",
    title: "Capitol Project Inspector",
    logo: {
      image: markham,
      relativePath: "assets/images/markhamTransparent.png",
      imageSlug: "markhamTransparent.png",
    },
  },
  {
    start: "March 2014",
    finish: "May 2017",
    company: "MGR Construction Services",
    title: "Vice President of Construction",
    logo: {
      image: mgr,
      relativePath: "assets/images/mgr.jpeg",
      imageSlug: "mgr.jpeg",
    },
  },
  {
    start: "June 2017",
    finish: "Present",
    company: "Diorite Contracting",
    title: "President",
    logo: {
      image: diorite,
      relativePath: "assets/images/dioriteLogoTransparent3.png",
      imageSlug: "dioriteLogoTransparent3.png",
    },
  },
  {
    start: "June 2018",
    finish: "Present",
    company: "Home Life Hearts Realty Inc Brokerage",
    title: "Realtor",
    logo: {
      image: homelife,
      relativePath: "assets/images/homelife.png",
      imageSlug: "homelife.png",
    },
  },
]

export const qualifications = [
  {
    school: "National University Manila Philippines",
    program: "Bachelor’s Degree, Civil Engineering",
    graduation: "1982",
    logo: { image: nu, relativePath: "assets/images/nationalUniversity" },
  },
  {
    school: "Seneca College",
    program: "Building Environmental System Certification",
    graduation: "1995",
    logo: { image: seneca, relativePath: "assets/images/seneca.svg" },
  },
  {
    school: "University of Toronto",
    program: "RDBMS Certification",
    graduation: "1999",
    logo: { image: uoft, relativePath: "assets/images/UofT.png" },
  },
  {
    school: "Ontario Real Estate Association",
    program: "Real Estate Licensing",
    graduation: "2009",
    logo: { image: orea, relativePath: "assets/images/OREA.png" },
  },
]

export const designations = [
  `Institute of Inspection Cleaning and Restoration Certification (IICRC)`,
  `Water Damage Restoration Technician (WRT)`,
  `Applied Microbial Restoration Technician (AMRT)`,
  `Certified Mold Inspector (CMI)`,
]
