import React from "react"
import "./aboutProfile.scss"
import GatsbyImageSharpFluid from "../components/gatsbyImageSharpFluid.js"

const AboutProfile = () => {
  return (
    <div className="profile">
      <div className="profile__border">
        <GatsbyImageSharpFluid
          imgsrc={"profile.jpg"}
          className={"profile__picture"}
        />
      </div>
      <div className="profile__description">
        <p className="profile__summary">
          <span>President</span> of Diorite Contracting. <br />
          <span>Realtor</span> at Home Life Hearts Realty Inc.
          <br />
          <span>Pastor</span> of JNCIM Markham church.
        </p>
        <p>
          After decades of experience working with homes and building a home for
          my wife, daughters, and grandchildren, I know the value and importance
          of building a home for your family that is beautiful, safe and
          comfortable.
        </p>
      </div>
    </div>
  )
}

export default AboutProfile
