import React from "react"
import { qualifications } from "../assets/data/expertise.js"
import education from "../assets/icons/education.svg"
import { v4 as uuidv4 } from "uuid"

const AboutExpertiseEducation = () => {
  return (
    <div className="expertise__list">
      <h2 className="expertise__subtitle">
        <img
          src={education}
          className="icon icon--education"
          alt="mortarboard cap icon for the education section"
        />
        Education
      </h2>
      {qualifications.reverse().map(study => {
        return (
          <div className="job" key={uuidv4()}>
            <div>
              <p className="job__duration">{study.graduation}</p>
              <h3 className="job__company">{study.school}</h3>
              <h4 className="job__title">{study.program}</h4>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AboutExpertiseEducation
