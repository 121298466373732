import React from "react"
import "./aboutSubNav.scss"
import { Link } from "gatsby"

const AboutSubNav = () => {
  return (
    <aside className="sub-nav">
      <ul>
        <li>
          <Link to="/realEstate">
            <h3>.01</h3>
            <p>
              Buying or <br />
              Selling?
            </p>
          </Link>
        </li>
        <li>
          <Link to="/diorite">
            <h3>.02</h3>
            <p>
              Renovating <br />
              your Home?
            </p>
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <h3>.03</h3>
            <p>
              Want to Get <br />
              Connected?
            </p>
          </Link>
        </li>
      </ul>
    </aside>
  )
}

export default AboutSubNav
