import React from "react"
import Layout from "../components/layout.js"
import BackgroundSection from "../components/backgroundSection.js"
import AboutProfile from "../components/aboutProfile.js"
import AboutExpertise from "../components/aboutExpertise.js"
import AboutConnectNav from "../components/aboutConnectNav.js"
import AboutSubNav from "../components/aboutSubNav.js"
import AboutCompanyLogos from "../components/aboutCompanyLogos.js"
import Head from "../components/head.js"
import "./about.scss"

const About = () => {
  return (
    <section className="about-pg">
      <Head title={"About"} />
      <BackgroundSection
        imgsrc={"aboutbg.jpg"}
        page={"home"}
        className="about-pg__background"
      >
        <Layout page={"about"}>
          <AboutProfile />
          <AboutConnectNav />
          <AboutExpertise />
          <AboutSubNav />
          <AboutCompanyLogos />
        </Layout>
      </BackgroundSection>
    </section>
  )
}

export default About
