import React from "react"
import { workList } from "../assets/data/expertise.js"
import work from "../assets/icons/work.svg"
import { v4 as uuidv4 } from "uuid"

const AboutExpertiseExperience = () => {
  return (
    <div className="expertise__list">
      <h2 className="expertise__subtitle">
        <img
          src={work}
          className="icon icon--work"
          alt="briefcase icon for the experience section"
        />
        Experience
      </h2>
      {workList.reverse().map(item => {
        return (
          <div className="job" key={uuidv4()}>
            <div>
              <p className="job__duration">
                {item.start} - {item.finish}
              </p>
              <h3 className="job__company">{item.company}</h3>
              <h4 className="job__title">{item.title}</h4>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AboutExpertiseExperience
